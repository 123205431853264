export function format(dateString: string) {
  const date = parseDate(dateString)

  return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
}

export function formatISO8601(dateString: string) {
  const date = parseDate(dateString)

  return date.toISOString()
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export function formatLong(dateString: string) {
  const date = parseDate(dateString)
  const month = months[date.getUTCMonth()]

  return `${month} ${date.getUTCDate()}, ${date.getUTCFullYear()}`
}

export function formatDateWithTimezone(dateString: string) {
  const date = new Date(dateString)

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: date.getTimezoneOffset() === 0 ? 'UTC' : undefined,
    timeZoneName: 'short'
  } as Intl.DateTimeFormatOptions

  const formatter = new Intl.DateTimeFormat('en-US', options)
  const formattedParts = formatter.formatToParts(date)

  let time = ''
  let timezone = ''

  for (const part of formattedParts) {
    if (part.type === 'hour') {
      time += parseInt(part.value).toString() // Remove leading zero
    } else if (part.type === 'minute') {
      time += `:${part.value}`
    } else if (part.type === 'timeZoneName') {
      timezone += part.value
    }
  }

  return `${time} ${timezone}`
}

export function getUTCTime(dateString: string) {
  const date = parseDate(dateString)
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

  const ampm = hours >= 12 ? 'PM' : 'AM'
  const hours12 = hours % 12 || 12

  return `${hours12}:${formattedMinutes} ${ampm} PST`
}

function parseDate(dateString: string) {
  if (dateString.includes('T')) {
    return new Date(dateString)
  } else {
    return new Date(Date.parse(`${dateString}T00:00:00.000Z`))
  }
}
